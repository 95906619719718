export const environment = {
    baseURL: 'https://api-prod.crizac.com/v1/api/',
    fileBaseURL: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseURL: 'https://www.crizac.com/yeasterdownload/',
    validatePassportURL: 'https://nps2entus1.execute-api.ap-south-1.amazonaws.com/default/ocr',
    currentLocationURL: 'https://ipapi.co/json/',
    basePHPURL: 'https://accounts.crizac.com/api_mongo',
    baseDevPHPURL: 'https://admin.crizac.com/apimongo',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',
    apiPHPURLv3: 'https://accounts.crizac.com/',
    paypalClientId: 'AW_65nCjsPQ9Ldw-woPwzeD8BrgTPpEjo06K-aOfgKMiDgFW7orip8P4XsnHYwTUKoHf4VPdB1JhC7Xc',
    paymentPercentage: 10,
    chatApi: "wss://api-console.crizac.com/support-chat-notification",
    chatURL: "https://api-console.crizac.com/v1/api/",
    notificationApi: "wss://api-console.crizac.com",
    redirectionPassword: 'crizac10',
    mailDomain: 'crizac.com',
    showCourses:true,
    showUniversity: false,
    showChat: false,
    showCompareCourse: false,
    showAdvanceFilter:false,
    showFinance:true,
    showOldEnquiry:true,
    ShowNewEnquiry:false,
    useApplicationPaymentFlow: true,
    worldPaymerchantId: 'PO4058892922',
    tenant:1,
    APP_VERSION: "0.0.19"
};
